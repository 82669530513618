/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/theme.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.text-primary {
    color: #CC3179 !important;
}

.logo {
    margin: 20px;
    max-width: 120px;
    // position: fixed;
}
.mat-form-field-wrapper {
    padding-bottom: 4px !important;
}

b, strong {
  font-weight: 500;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  